import * as React from 'react'
import { colors } from './colors'
import { Text } from '../../elementals/Text'
import { formatNumber } from '../../../utils/number'
import { translate } from '../../../utils/translations'
import * as translations from './translations'
import { SquareRectangle } from '../../elementals/Icons/SquareRectangle'
import { Square } from '../../elementals/Icons/Square'
import { LiveUpdate } from './live-update'
import { useHashParam } from './use-hash-param'
import { Voter } from './types'
import { usernames } from './login'
import { groupBy, mapValues } from 'lodash'

type Props = {
  lastUpdated: number
  retrigger: () => void
  ortData: Array<Voter> | undefined
  allData: Array<Voter> | undefined
  orte: Array<string>
  selectedOrt: string
  setOrt: (newValue: string) => void
  showOnlyWithComments: boolean
  showOnlyWithCustomComments: boolean
  showDone: boolean
  showReserved: boolean
  setShowDone: React.Dispatch<React.SetStateAction<boolean>>
  setShowReserved: React.Dispatch<React.SetStateAction<boolean>>
  setShowOnlyWithComments: React.Dispatch<React.SetStateAction<boolean>>
  setShowOnlyWithCustomComments: React.Dispatch<React.SetStateAction<boolean>>
}

export const Header = ({
  ortData,
  lastUpdated,
  retrigger,
  orte,
  allData,
  selectedOrt,
  setOrt,
  showOnlyWithComments,
  showOnlyWithCustomComments,
  showDone,
  showReserved,
  setShowOnlyWithComments,
  setShowOnlyWithCustomComments,
  setShowDone,
  setShowReserved,
}: Props) => {
  const [username] = useHashParam('user')

  const userDialog = React.useRef<HTMLDialogElement | null>()

  const myReservationsText = translate({ translations, path: ['myReservations'] })

  const orteSelect = (
    <select
      style={{ width: 'auto', color: colors.text.default, zoom: '100%' }}
      value={selectedOrt}
      onChange={async event => {
        setOrt(event.target.value)
        await retrigger()
      }}
    >
      {orte.map(name => (
        <option key={name} value={name}>
          {name}
        </option>
      ))}
    </select>
  )

  const reservedHouseholdsByUser = usernames
    .map(otherUsername => {
      const totalReservationsByCurrentUser = allData?.filter(
        voter =>
          voter.reserviertVon === otherUsername &&
          (selectedOrt === myReservationsText || voter.ort === selectedOrt),
      )
      const groupedByHousehold = groupBy(
        totalReservationsByCurrentUser,
        person => `${person.ort}_${person.strasse}_${person.hausnummer}_${person.adresszusatz}`,
      )
      return {
        reservedBy: otherUsername,
        totalReservedHouseholds: Object.values(
          mapValues(groupedByHousehold, persons => persons.length > 0),
        ).filter(Boolean).length,
        doneReservedHouseholds: Object.values(
          mapValues(groupedByHousehold, persons =>
            persons.some(person => person.istAbgehandeltWorden === 1),
          ),
        ).filter(Boolean).length,
      }
    })
    .filter(({ totalReservedHouseholds }) => totalReservedHouseholds !== 0)
    .sort((a, b) => b.totalReservedHouseholds - a.totalReservedHouseholds)

  const ortDataByHousehold = groupBy(
    ortData,
    person => `${person.ort}_${person.strasse}_${person.hausnummer}_${person.adresszusatz}`,
  )

  return (
    <div
      style={{
        position: 'sticky',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        top: 0,
        backgroundColor: colors.pageBackground,
        paddingLeft: 8,
        zIndex: 2,
        gap: 4,
      }}
    >
      <div>
        {translate({ translations, path: ['households'] })}{' '}
        <Text style={{ color: colors.text.besuchte }}>
          {formatNumber(
            Object.values(ortDataByHousehold).filter(persons =>
              persons.every(person => person.istAbgehandeltWorden === 1),
            ).length ?? 0,
          )}
        </Text>
        /
        {formatNumber(
          Object.values(
            groupBy(
              ortData,
              person => `${person.strasse}_${person.hausnummer}_${person.adresszusatz}`,
            ),
          ).filter(persons => persons.length > 0).length ?? 0,
        )}
      </div>
      {orteSelect}
      <button
        style={{ paddingTop: 0, paddingBottom: 0, minHeight: 24, maxHeight: 24, zoom: '120%' }}
        onClick={() => userDialog.current?.showModal()}
      >
        <b>{translate({ translations, path: ['choose'] })}</b>
      </button>
      <dialog
        ref={node => {
          userDialog.current = node
        }}
        onClick={() => {
          userDialog.current?.close()
        }}
        style={{ marginTop: 8, fontSize: '0.66em' }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}
          onClick={event => {
            event.stopPropagation()
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '1.33em',
              marginBottom: 8,
              marginTop: 4,
            }}
          >
            <Text style={{ color: colors.text.user }}>{username}</Text>
          </div>
          {orteSelect}
          <button
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            onClick={() => setShowDone(prev => !prev)}
          >
            {showDone ? <SquareRectangle /> : <Square />}
            {translate({ translations, path: ['showVisited'] })}
            <span />
          </button>{' '}
          <button
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            onClick={() => setShowReserved(prev => !prev)}
          >
            {showReserved ? <SquareRectangle /> : <Square />}
            {translate({ translations, path: ['showReservedByOther'] })}
            <span />
          </button>
          <button
            onClick={async () => {
              setOrt(translate({ translations, path: ['myReservations'] }))
              await retrigger()
              userDialog.current?.close()
            }}
          >
            {translate({
              translations,
              path: ['showMyReservations'],
              passed:
                reservedHouseholdsByUser.find(({ reservedBy }) => reservedBy === username)
                  ?.totalReservedHouseholds ?? 0,
            })}
          </button>
          <button
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            onClick={async () => {
              setShowOnlyWithComments(prev => !prev)
              await retrigger()
              userDialog.current?.close()
            }}
          >
            {showOnlyWithComments ? <SquareRectangle /> : <Square />}
            {translate({ translations, path: ['showOnlyWithComments'] })}
            <span />
          </button>
          <button
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            onClick={async () => {
              setShowOnlyWithCustomComments(prev => !prev)
              await retrigger()
              userDialog.current?.close()
            }}
          >
            {showOnlyWithCustomComments ? <SquareRectangle /> : <Square />}
            {translate({ translations, path: ['showOnlyWithCustomComments'] })}
            <span />
          </button>
          <button
            onClick={async () => {
              await retrigger()
              userDialog.current?.close()
            }}
            autoFocus
          >
            <LiveUpdate lastUpdated={lastUpdated} />
          </button>
          <div style={{ marginTop: 8 }}>
            {selectedOrt === myReservationsText
              ? translate({ translations, path: ['otherReservations'] })
              : translate({
                  translations,
                  path: ['otherReservationsInPlace'],
                  passed: {
                    place: selectedOrt,
                    count: Object.values(ortDataByHousehold).filter(persons =>
                      persons.some(
                        person =>
                          typeof person.reserviertVon === 'string' &&
                          person.reserviertVon.length > 0,
                      ),
                    ).length,
                  },
                })}{' '}
            <ol>
              {reservedHouseholdsByUser.map(
                ({ doneReservedHouseholds, reservedBy, totalReservedHouseholds }) => (
                  <li
                    key={reservedBy}
                    style={{
                      color: reservedBy === username ? colors.text.user : undefined,
                      listStyleType: 'unset',
                    }}
                  >
                    {reservedBy}: {formatNumber(doneReservedHouseholds)}/
                    <Text
                      style={{
                        color: reservedBy === username ? undefined : colors.text.eu,
                      }}
                    >
                      {formatNumber(totalReservedHouseholds)}
                    </Text>
                  </li>
                ),
              )}
            </ol>
          </div>
        </div>
      </dialog>
    </div>
  )
}
