import * as React from 'react'
import { Text } from '../../elementals/Text'
import { translate } from '../../../utils/translations'
import * as translations from './translations'
import { colors } from './colors'
import { Line } from './line'
import { useHashParam } from './use-hash-param'
import { Header } from './header'
import { Voter } from './types'
import { xhr } from '../../../xhr'
import { useAuthToken } from './login'
import { besuchenNotwendigTags, besuchenUnnötigTags } from './tags'

const useVoters = (authToken: string | undefined) => {
  const [{ data, lastUpdated }, setData] = React.useState<{
    data: Array<Voter> | undefined
    lastUpdated: number
  }>({ data: undefined, lastUpdated: 0 })

  const trigger = React.useCallback(async () => {
    if (authToken) {
      const response = await xhr.get('/api/voters', {
        headers: {
          Authorization: 'Bearer ' + authToken,
        },
      })
      setData({ data: response.data, lastUpdated: new Date().valueOf() })
    }
  }, [setData, authToken])

  React.useEffect(() => {
    trigger()
  }, [trigger])

  return {
    data,
    lastUpdated,
    isLoading: data === undefined,
    retrigger: trigger,
  }
}

export const Page = () => {
  React.useEffect(() => {
    document.title = translate({ translations, path: ['gw'] })
  }, [])

  const authToken = useAuthToken()

  const { data: allData, lastUpdated, retrigger } = useVoters(authToken)
  const [username] = useHashParam('user')
  const [ort, setOrt] = useHashParam('ort')

  const [showDone, setShowDone] = React.useState(true)
  const [showReserved, setShowReserved] = React.useState(true)
  const [showOnlyWithComments, setShowOnlyWithComments] = React.useState(false)
  const [showOnlyWithCustomComments, setShowOnlyWithCustomComments] = React.useState(false)

  const myReservationsText = translate({ translations, path: ['myReservations'] })

  let doIHaveReservations = false
  const orte = allData
    ? [myReservationsText].concat(
        allData.reduce((acc, { ort, reserviertVon }) => {
          if (reserviertVon === username) {
            doIHaveReservations = true
          }
          return acc.includes(ort) ? acc : acc.concat(ort)
        }, [] as Array<string>),
      )
    : []

  const selectedOrt = ort ?? (doIHaveReservations ? orte[0] : orte[1])

  const tags = (allData ?? [])
    .flatMap(({ tags }) => tags ?? [])
    .reduce((acc, current) => {
      const index = acc.findIndex(({ tag }) => tag === current)
      if (index !== -1) {
        acc[index] = { ...acc[index], occurrences: acc[index].occurrences + 1 }
      } else {
        acc.push({ tag: current, occurrences: 1 })
      }
      return acc
    }, [] as Array<{ occurrences: number; tag: string }>)
    .sort((a, b) => {
      const sort = b.occurrences - a.occurrences
      return sort === 0 ? b.tag.localeCompare(a.tag) : sort
    })
    .map(({ tag }) => tag)

  const ortData = allData?.filter(({ ort, reserviertVon }) =>
    selectedOrt === myReservationsText ? reserviertVon === username : ort === selectedOrt,
  )

  const ortDataForUser = ortData?.filter(
    ({ istAbgehandeltWorden, reserviertVon, tags }) =>
      (showDone ? true : !istAbgehandeltWorden) &&
      (showReserved ? true : [null, '', username].includes(reserviertVon)) &&
      (showOnlyWithComments ? tags.length > 0 : true) &&
      (showOnlyWithCustomComments
        ? tags.filter(
            tag => !besuchenNotwendigTags.includes(tag) && !besuchenUnnötigTags.includes(tag),
          ).length > 0
        : true),
  )

  console.log(
    ortDataForUser?.map(
      it =>
        `${it.ort} ${it.strasse} ${it.hausnummer}${it.adresszusatz ? '/' + it.adresszusatz : ''} ${
          it.name
        } ~${new Date().getFullYear() - it.geburtsjahr}${
          it.istEU ? ' ' + translate({ translations, path: ['eu-citizen'] }) : ''
        } ${it.tags.map(tag => '«' + tag + '»').join(', ')}`,
    ),
  )

  const openPeople = ortData?.filter(({ istAbgehandeltWorden }) => istAbgehandeltWorden === 0)
  const isOrtDone = openPeople?.length === 0
  const isRestReserved =
    (openPeople?.length ?? 0) > 0 &&
    openPeople?.every(
      ({ reserviertVon }) =>
        reserviertVon !== '' && reserviertVon !== null && reserviertVon !== username,
    )

  const showOrt = selectedOrt === myReservationsText

  return (
    <div
      style={{
        backgroundColor: colors.pageBackground,
        color: colors.text.default,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        zoom: '150%',
      }}
    >
      <Header
        allData={allData}
        lastUpdated={lastUpdated}
        ortData={ortData}
        orte={orte}
        retrigger={retrigger}
        selectedOrt={selectedOrt}
        setOrt={setOrt}
        setShowDone={setShowDone}
        setShowOnlyWithComments={setShowOnlyWithComments}
        setShowOnlyWithCustomComments={setShowOnlyWithCustomComments}
        setShowReserved={setShowReserved}
        showDone={showDone}
        showOnlyWithComments={showOnlyWithComments}
        showOnlyWithCustomComments={showOnlyWithCustomComments}
        showReserved={showReserved}
      />
      <datalist id="tags">
        {tags.map(tag => (
          <option key={tag}>{tag}</option>
        ))}
      </datalist>
      {(isOrtDone || isRestReserved) && (
        <div
          style={{
            position: 'sticky',
            top: 10,
            backgroundColor: colors.pageBackground,
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            width: 'auto',
            justifyContent: 'center',
            rowGap: 8,
            alignItems: 'center',
            padding: 16,
          }}
        >
          <Text style={{ fontSize: '1.5em', color: colors.text.default, wordBreak: 'break-word' }}>
            {!doIHaveReservations && selectedOrt === myReservationsText
              ? translate({ translations, path: ['youHaveNoReservations'], passed: selectedOrt })
              : isRestReserved
              ? translate({ translations, path: ['yourWorkDone'], passed: selectedOrt })
              : translate({ translations, path: ['allWorkDone'], passed: selectedOrt })}
          </Text>
          {!showReserved && isRestReserved && (
            <button
              onClick={() => {
                setShowReserved(true)
                setShowDone(false)
              }}
            >
              {translate({ translations, path: ['showReservedByOther'] })}
            </button>
          )}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '2px',
        }}
      >
        {ortDataForUser?.map(voter => (
          <Line
            key={voter.id}
            {...voter}
            retrigger={retrigger}
            username={username}
            authToken={authToken}
            showOrt={showOrt}
            alwaysShowComments={showOnlyWithCustomComments}
          />
        ))}
      </div>
    </div>
  )
}
