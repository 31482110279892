import * as React from 'react'

import { Badge } from '../../elementals/Badge'
import { colors } from './colors'
import { Voter } from './types'
import { xhr } from '../../../xhr'

export const besuchenNotwendigTags = [
  'links',
  'SPÖ-Mitglied',
  'bitte besuchen',
  'Team Michelhausen',
]
export const besuchenUnnötigTags = ['rechts', 'ÖVP-Kandidat', 'FPÖ-Kandidat']

type Props = Pick<Voter, 'id' | 'tags'> & {
  retrigger: () => void
  authToken: string | undefined
  alwaysShowComments: boolean
  showTagsInput: boolean
  setLineDonePending: React.Dispatch<React.SetStateAction<boolean>>
  istAbgehandeltWorden: boolean
}

export const Tags = ({
  tags,
  retrigger,
  authToken,
  id,
  showTagsInput,
  setLineDonePending,
  istAbgehandeltWorden,
  alwaysShowComments,
}: Props) => {
  const filteredTags = alwaysShowComments
    ? tags?.filter(
        tag => !besuchenNotwendigTags.includes(tag) && !besuchenUnnötigTags.includes(tag),
      )
    : tags

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: 4,
      }}
    >
      {filteredTags.map(tag => (
        <Badge
          style={{
            backgroundColor: istAbgehandeltWorden ? colors.lineBackground : colors.pageBackground,
            color: besuchenNotwendigTags.includes(tag)
              ? colors.text.besuchenNotwendig
              : besuchenUnnötigTags.includes(tag)
              ? colors.text.besuchenUnnötig
              : colors.text.default,
          }}
          key={tag}
        >
          {tag}
          {showTagsInput && (
            <button
              style={{ border: 'none', borderRadius: 100 }}
              onClick={async () => {
                setLineDonePending(true)
                await xhr.patch(
                  '/api/voters',
                  { id, tags: (tags ?? []).filter(otherTag => otherTag !== tag) },
                  { headers: { Authorization: 'Bearer ' + authToken } },
                )
                await retrigger()
                setLineDonePending(false)
              }}
            >
              ✕
            </button>
          )}
        </Badge>
      ))}
    </div>
  )
}
